@font-face {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/Helvetica.ttf") format("truetype");
}
@font-face {
    font-family: "Helvetica Light";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/Helvetica-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/HelveticaNeue.otf") format("truetype");
}
@font-face {
    font-family: "YK Regular";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/fonts/YanoneKaffeesatz-Regular.ttf") format("truetype");
}

html {
    height: 100%;
    min-height: 500px;
}
body {
    margin: 0;
    font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a {
        color: #1f7cc1;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "YK Regular", "Helvetica Neue", Helvetica, sans-serif;
    }

    input.form-control {
        border-radius: 2px;
        &::placeholder {
            font-family: "Helvetica Neue", "Helvetica" !important;
        }
    }

    #root {
        display: flex;
        width: 100%;
    }
    font-size: 14px;
    display: flex;
    height: 100%;
    min-height: 500px;
    background-image: url("./assets/images/banner_img_min.png");
    background-repeat: no-repeat;
    background-size: cover;
}

button.btn {
    font-family: "Helvetica Neue", "Helvetica";
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
    &.btn-primary {
        background-color: #1f7cc1;
    }
    &.btn-link {
        color: #fff;
        font-size: 14px;
    }
}

.form-check-input:checked {
    background-color: #1f7cc1;
    border-color: #1f7cc1;
}

.App {
    display: flex;
    flex-direction: column;
    width: 100%;
    header {
        width: 100%;
        nav {
            width: 100%;
            background-color: #021323;
            border-bottom: solid 2px #1f7cc1;
            .navbar-brand {
                height: 85px;
                width: 260px;
                background-image: url("./assets/images/logo.png");
                background-size: 260px;
                background-repeat: no-repeat;
                background-position: center center;
                @media (max-width: 768px) {
                    width: 260px;
                    background-size: 200px;
                }
                @media (max-width: 460px) {
                    background-position: left center;
                    width: 50px;
                }
            }

            &.camplify-login {
                background-color: #297ac3;
                .navbar-brand {
                    background-image: url("./assets/images/camplify.min.svg");
                }
            }
            .navbar-toggler {
                background-color: #1f7cc1;
            }
            .navbar-nav {
                margin-top: 26px;
                .nav-link {
                    color: #fff !important;
                }
                @media (max-width: 768px) {
                    margin-top: 20px;
                    .nav-link {
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    .sign-in {
        color: #fff;
        margin-top: 100px;
        margin-left: auto;
        margin-right: auto;
        width: 360px;
        align-items: center;
        font-family: "Helvetica Neue";
        h2 {
            text-align: center;
        }
        .inline {
            display: flex;
            align-items: center;
            justify-content: space-between;
            div {
                display: inline-block;
            }
            label {
                margin: 0;
                line-height: auto;
                display: inline-block;
            }
            input {
                margin: 0.2rem 0.5rem 0 0;
                display: inline-block;
            }
            button {
                padding-right: 0;
            }
        }

        .login-options {
            h5 {
                text-align: center;
                margin-top: 3rem;
            }

            & > div {
                display: flex;
                justify-content: space-between;
                &.back {
                    margin-top: 2rem;
                    display: block;
                    text-align: center;
                }
                a {
                    color: #d3d3d3;
                    svg {
                        font-size: 1.2rem;
                        font-weight: bold;
                        margin-right: 0.5rem;
                    }
                }
                img {
                    margin-left: 0.5rem;
                    height: 18px;
                }
            }
        }

        button[type="submit"] {
            margin-top: 1.5rem;
        }
        button.btn-link {
            padding: 0;
        }
    }

    .powered-by {
        @media (max-height: 600px) {
            display: none;
        }
        margin-left: 1rem;
        padding: 1rem 2rem;
        color: #d3d3d3;
        position: absolute;
        bottom: 0;
        background-image: url("./assets/images/logo_dark.png");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 20px 20px;
    }
}
